.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  
}

.btn_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  width: 80%;
  height: 55%;
}

.btn {
  display: flex;
  background-color: rgb(255, 255, 255, 0.5);
  fill-opacity: 10;
  border-radius: 15px;
  width: 80%;
  height: 40%;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;

  border: 0.1px solid;
  padding: 5px;
  box-shadow: 1px 2px 10px #afaeae;
  border-color: rgb(223, 217, 210);
}

.box_head {
  display: flex;
  flex-direction: row;
  margin-top: -50px;
  margin-bottom: 20px;
}

.icon_box {
  display: flex;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
}

.text_btn_box{
  width: 80%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.icon {
  width: 200px;
  height: 200px;
}

.text1 {
  font-size: 30px;
  text-wrap: balance;
  color: #666;
  text-align: center;
}

.text_head {
  font-size: 70px;
}



