.container {
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    flex-direction: column;
    /* padding: 40px; */
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
  
  .box_head {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .text_head {
    font-size: 70px;
  }

  .view_alert {
    width: 80%;
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_alert {
    font-size: 30px;
    text-wrap: balance;
    text-align: center;
    font-weight: 300;
  }

  .export_btn {
    width: 600px;
    height: 70px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .btn_view {
    width: 80%;
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .text_btn {
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
  }
  
  .image {
      width: 90%;
      margin-top: 50px;
  }

  .icon {
    width: 40%;
    /* height: 200px; */
  }

  .inBtn_view {
    display: flex;
    justify-content: center;
    align-items: center;
  }