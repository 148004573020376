.container {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* padding: 40px; */
  background-color: #ffffff;
}

.box_head {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  margin-bottom: 20px;
}

.text_head {
  font-size: 70px;
}

.image {
    width: 100%;
    margin-top: 50px;
}