.container {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* padding: 40px; */
  
}

.icon {
  width: 300px;
  height: 300px;
  margin-top: 50px;
}

.box_head {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.box_text1 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 90%;
  align-items: flex-start;
}

.box_text2 {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  width: 90%;
  align-items: flex-start;
}

.box_text3 {
  display: flex;
  margin-top: 70px;
  width: 90%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.box_text4 {
  display: flex;
  /* margin-top: 70px; */
  width: 90%;
  align-items: flex-start;
  flex-wrap: wrap;
}

.box_text_2page {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.in_box_2page {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 25px;
  margin-bottom: 25px;
}

.box_btn_circle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text_head {
  font-size: 70px;
}

.text_head2 {
  font-size: 50px;
  color: #666;
}

.text_head_sub {
  font-size: 30px;
  color: #b28a4c;
  /* margin-top: 10px; */
}

.text_head_sub2 {
  font-size: 35px;
  color: #b28a4c;
  /* margin-top: 10px; */
}

.text1 {
  font-size: 30px;
  text-wrap: wrap;
  color: #666;
}

.inLine {
  display: inline;
}

h1 {
  display: inline-block;
}