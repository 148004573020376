.container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  
}

.btn_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  width: 80%;
  /* height: 55%; */
}

.btn {
  display: flex;
  background-color: rgb(255, 255, 255, 0.5);
  fill-opacity: 10;
  border-radius: 15px;
  width: 80%;
  /* height: 40%; */
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 0.1px solid;
  box-shadow: 1px 2px 5px #afaeae;
  border-color: rgb(223, 217, 210);
  margin: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.box_head {
  display: flex;
  flex-direction: row;
  margin-top: 300px;
  margin-bottom: 20px;
}

.icon_box {
  display: flex;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.text_btn_box {
  width: 80%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.icon {
  width: 200px;
  height: 200px;
}

.text1 {
  font-size: 30px;
  text-wrap: balance;
  color: #b28a4c;
  text-align: center;
  font-weight: bold;
}
.text2 {
  font-size: 30px;
  text-wrap: balance;
  color: #666666;
  text-align: center;
  font-weight: bold;
}
.text3 {
  font-size: 30px;
  text-wrap: balance;
  color: #666666;
  text-align: center;
  font-weight: 500;
}

.text_head {
  font-size: 70px;
}
.button {
  background-color: #b28a4c;
  border: none;
  color: white;
  padding: 10px 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.text4 {
  font-size: 30px;
  text-wrap: balance;
  color: #ffffff;
  text-align: center;
}
