.container {
    display: flex;
  }

.card {
  display: flex;
  background-color: rgb(255, 255, 255, 0.5);
  fill-opacity: 10;
  border-radius: 15px;
  width: 80%;
  /* height: 30%; */
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  margin-top: 2vh;

  border: 0.1px solid;
  padding: 5px;
  box-shadow: 1px 2px 10px #afaeae;
  border-color: rgb(223, 217, 210);
}

.icon {
  width:100px;
  height: 100px;
  margin-top: 20px;
}

.box_text {
  width: 80%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.box_text2 {
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
}

.line {
  width: 90%; 
  height: 2px;
  background-color: #D4D4D4;
}

.text_head {
  font-size: 50px;
  color: #B28A4C;
  text-align: center;
}

.text1 {
  font-size: 35px;
  color: #666;
  text-align: center;
}